<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">계약현황</h2>
      </div>
      <div class="body_section">
        <ContractInfoListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headRight>
            <div class="group_form">
              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 계약상태 -->
            <col style="width: 160px" />
            <!-- 계약번호  -->
            <col style="width: 160px" />
            <!-- 계약명 -->
            <col />
            <!-- 계약금액 -->
            <col style="width: 120px" />
            <!-- 계약시작일 -->
            <col style="width: 120px" />
            <!-- 계약종료일 -->
            <col style="width: 120px" />
          </template>
          <template v-slot:tr>
            <th>계약상태</th>
            <th>계약번호</th>
            <th>계약명</th>
            <th>계약금액</th>
            <th>계약시작일</th>
            <th>계약종료일</th>
          </template>
          <template v-slot:row="slotProps">
            <ContractInfoListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="6" class="td_empty">계약내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup />
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import ContractInfoListFilterBar from "@/components/user/contract/list/ContractInfoListFilterBar";
import ContractInfoListLine from "@/components/user/contract/list/ContractInfoListLine";
import ListPage from "@/components/shared/ListPage";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

import { mapState } from "vuex";
import { GET_CONTRACT_LIST } from "@/store/modules/contract/action";

export default {
  components: {
    ListPage,
    PageWithLayout,
    TableHead,
    SelectBoxPageRange,
    ContractInfoListFilterBar,
    ContractInfoListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  props: {
    rowIndex1: Number,
    rowIndex2: Number,
  },
  data() {
    return {
      API_PATH: this.$apiPath.CONTRACT,

      defaultKeywordSelectedId: "description",
      size: 100, // 계약만 100개가 디폴트.
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => {
        const arr = [{ code: "0", name: "전체" }];
        return arr.concat(state.contract.statusList);
      },
    }),
    keywordList() {
      return [
        { id: "description", code: "description", desc: "계약명" },
        { id: "contractCid", code: "contractCid", desc: "계약번호" },
      ];
    },
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_CONTRACT_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objDates = this.checkQueryDates(query, "contractStartDate1", "contractStartDate2");

      const ret = Object.assign({}, objPaging, objKeyword, objDates);
      const { contractStatus } = query;

      if (contractStatus) {
        this.defaultStateId = contractStatus;
        ret.contractStatus = contractStatus;
      }

      return ret;
    },
    getFilterbarParams(objData, _isForExcel) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["contractStartDate1"] = startDt;
        params["contractStartDate2"] = endDt;
      }

      if (status) {
        // params.status = status;
        params.contractStatus = status;
      }

      return params;
    },
  },
};
</script>
