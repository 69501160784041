<template>
  <tr>
    <!-- 계약상태 -->
    <td>{{ rowData.contractStatus || "" }}</td>
    <!-- 계약번호 -->
    <td>{{ rowData.contractCid || "" }}</td>
    <!-- 계약명 -->
    <td class="td_ellip">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.description || ''"
        v-html="rowData.description || ''"
      />
    </td>
    <!-- 최종계약금액 -->
    <td class="align_right">
      {{ finalPrice }}
    </td>
    <!-- 계약시작일 -->
    <td>{{ contractStartDate }}</td>
    <!-- 계약종료일 -->
    <td>{{ contractEndDate }}</td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";

import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "ContractInfoListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 상세 경로
    toPath() {
      const { contractId } = this.rowData;
      return makePathWithQuery(this.$routerPath.CONTRACT_VIEW, contractId, this.$route.query);
    },
    // 계약시작일
    contractStartDate() {
      const { contractStartDate } = this.rowData;
      if (!contractStartDate) return "";

      return getDateString(contractStartDate);
    },
    // 계약종료일
    contractEndDate() {
      const { contractEndDate } = this.rowData;
      if (!contractEndDate) return "";

      return getDateString(contractEndDate);
    },
    // 최종계약금액
    finalPrice() {
      const { finalPrice } = this.rowData;
      if (!finalPrice) return "";

      return addCommaForMoney(finalPrice);
    },
  },
};
</script>
